<script>
	export default {
		name:"MoneyList",
		components: {
			'mt-actionsheet': app.Mint.Actionsheet,
		},
		props:[
			'transferMethodData',
			'bankcardSheetData',
			'sheet',
		],
	}
</script>
<template>
	<div>
		<mt-actionsheet :actions="bankcardSheetData" v-model="sheet.bankcard"></mt-actionsheet>
        <mt-actionsheet :actions="transferMethodData" v-model="sheet.remittanceMethod"></mt-actionsheet>
	</div>
</template>